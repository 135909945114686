import * as React from "react";

import { ApplicationProperties, User } from "../../../types/chi";
import { CrudResource, isPagedRestRepositoryLink, PagingAndSortingRepository } from "../../../utils/HateoasUtils";
import UserComponent from "./User";
import styles from "./style/admin.scss";

type UsersProperties = {
  csrf: ApplicationProperties.Csrf
};

type UsersState = {
  users: CrudResource<User>[]
  links: PagingAndSortingRepository<User>["_links"]
}

class UsersComponent extends React.Component<UsersProperties, UsersState> {

  constructor(props: UsersProperties) {
		super(props);
    this.state = {
      users: [],
      links: {}
    };
  }

  componentDidMount(): void {
    this.fetchUsers();
  }

  private fetchUsers(url?: string, page: number = 0, size: number = 20) {
    const { users, links } = this.state;

    if (!url) {
      url = `/api/users?page=${page}&size=${size}`;
    }

    users.length = 0;
    for (const key in links) {
      if (isPagedRestRepositoryLink(key)) {
        delete links[key];
      }
    }


    fetch(url)
    .then(response => response.json())
    .then((repository: PagingAndSortingRepository<User>) => {
      for (const key in repository._links) {
        if (isPagedRestRepositoryLink(key)) {
          links[key] = repository._links[key];
        }
      }

      return Promise.all(
      (repository._embedded.users)
        .map(resource => fetch(resource._links.self.href)
          .then(response => response.json())
          .then(user => users.push(user)))
      )
    })
    .then(_ => {
      users.sort((a, b) => (a.id - b.id));
      this.setState({ users })
    });
  }

  render() {
    const { csrf } = this.props;
    const { users, links } = this.state;

		return (
			<div id={styles.main}>
        <table>
          <thead>
            <tr>
              <th>First Name</th>
              <th>Last Name</th>
              <th>Email</th>
              <th>Created</th>
              <th>Expires</th>
              <th>License</th>
            </tr>
          </thead>
          <tbody>
            {users.map(user => <UserComponent key={(user as any)._links.self.href} user={user} csrf={csrf} />)}
          </tbody>
        </table>
        <div>
          <button key="first" onClick={() => this.fetchUsers(links["first"].href)} disabled={!("first" in links)}>&lt;&lt;</button>
          <button key="prev" onClick={() => this.fetchUsers(links["prev"].href)} disabled={!("prev" in links)}>&lt;</button>
          <button key="next" onClick={() => this.fetchUsers(links["next"].href)} disabled={!("next" in links)}>&gt;</button>
          <button key="last" onClick={() => this.fetchUsers(links["last"].href)} disabled={!("last" in links)}>&gt;&gt;</button>
        </div>
			</div>
		)
	}
}

export default UsersComponent;
