import * as React from "react";

import { ApplicationProperties, Language, License, User } from "../../../types/chi";
import { CrudResource } from "../../../utils/HateoasUtils";
import { ApplicationResources, ApplicationView } from "../App";
import styles from "./style/user.scss";

type UserProperties = {
  language: ApplicationView['language']
  resources: ApplicationResources
  csrf: ApplicationProperties.Csrf
  user: ApplicationProperties.User
}

type UserState = {
  user: User
}

class UserComponent extends React.Component<UserProperties, UserState> {

  constructor(props: UserProperties) {
		super(props);
    this.state = {
      user: null
    };
  }

  private passwordInput: HTMLInputElement;
  private resultDiv: HTMLSpanElement;

  componentDidMount() {
    fetch(`api/users/search/findByEmail?email=${this.props.user.name}`)
      .then(response => response.json())
      .then((user: CrudResource<User>) => Promise.all([
        fetch(user._links.license.href)
          .then(response => response.json())
          .then((license: License) => user.license = license),
        fetch(user._links.language.href)
          .then(response => response.json())
          .then((language: Language) => user.language = language),
      ]).then(_ => this.setState({ user })))
      .catch(errorData => console.error("errorData", errorData));
  }

  private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { csrf } = this.props;
    const { user } = this.state;

    event.stopPropagation();
    event.preventDefault();

    if (this.passwordInput.value.length < 5) {
      this.resultDiv.innerHTML = "&nbsp;<span style='color: red'>✖</span><span>Too short!</span>";
      return;
    }

    fetch(`api/users/${user.id}`, {
      method: "PATCH",
      headers: new Headers({
        "content-type": "application/json-patch+json",
        "x-csrf-token": csrf.value
      }),
      mode: "cors",
      body: JSON.stringify([
        {"op":"replace","path":"/password","value": this.passwordInput.value }
      ])
    })
    .then(response => {
      this.passwordInput.value = "";
      this.resultDiv.innerHTML = "&nbsp;<span style='color: green'>✓</span>";
    })
    .catch(errorData => {
      console.error("errorData", errorData);
      this.resultDiv.innerHTML = "&nbsp;<span style='color: red'>✖</span>";
    });
  }


  render() {
    const { language, resources } = this.props;
    const { user } = this.state;

		return (
			<div id={styles.main}>
        <div>&nbsp;</div>
        <div>{resources.messages[language.type]["app.user.name.label"]?.value} {user?.firstName} {user?.lastName}</div>
        <div>{resources.messages[language.type]["app.user.email.label"]?.value} {user?.email}</div>
        <div>{resources.messages[language.type]["app.user.license.label"]?.value} {user?.license.name}</div>
        <div>{resources.messages[language.type]["app.user.language.label"]?.value} {user?.language.name}</div>
        <div>
          {resources.messages[language.type]["app.user.password.label"]?.value}
          <input type="password" ref={(c) => this.passwordInput = c} />
          <button onClick={this.onClick}>
            {resources.messages[language.type]["app.user.password.button"]?.value}
          </button>
          <span ref={(c) => this.resultDiv = c}/>
        </div>
			</div>
		)
	}
}

export default UserComponent;
