import * as React from "react";

import { NavigationItem, NavigationSelected } from "./Navigation";

import styles from "./style/select.scss";

type SelectProperties = {
  navigation: {
    parts: {
      [id: string]: NavigationItem[];
    };
    selected: NavigationSelected;
  }
  visible: boolean;
  changeContent: (tag: string) => void;
};

class SelectComponent extends React.Component<SelectProperties> {
  private onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.stopPropagation();
    this.props.changeContent(event.target.value);
  }

  private getOptions(level: number, items: NavigationItem[], prefix: string = null, parentId: number = null) {
    let options: JSX.Element[] = [];
    const nextLevel = level + 1;

    items.forEach(item => {
      const key = prefix == null ? `${item.id}` : `${prefix}_${item.id}`

      let childOptions: JSX.Element[] = [];
      if (item.items && item.items.length > 0) {
        childOptions = this.getOptions(nextLevel, item.items, key, item.id);
      }

      if (level == 0) {
        if (item.items && item.items.length > 0) {
          options.push(<optgroup key={key} label={`  ${item.name}`} style={{background: '#EEE'}}></optgroup>);
        } else {
          options.push(<option key={key} value={key} style={{fontWeight: 'bold', background: '#EEE'}}>&nbsp;&nbsp;{item.name}</option>);
        }
      }
      else if (item.items && item.items.length > 0) {
        childOptions = [<optgroup key={key} label={`${'\xa0\xa0\xa0\xa0'}${item.id}. ${item.name}`} style={{background: '#EFEFEF'}}>{childOptions}</optgroup>];
      }
      else if (level == 2) {
        options.push(<option key={key} value={key} style={{background: '#FFF'}}>{`${'\xa0\xa0\xa0\xa0'}${parentId}.${item.id}`}. {item.name}</option>);
      } else {
        options.push(<option key={key} value={key} style={{background: '#FFF'}}>{`${'\xa0\xa0\xa0\xa0'}${item.id}`}. {item.name}</option>);
      }

      if (childOptions) {
        options = options.concat(childOptions)
      }
    });

    return options;
  }

  render() {
    const { navigation, visible } = this.props;
    const { onChange } = this;

    const options = Object.keys(navigation.parts).map(part => this.getOptions(0, navigation.parts[part], part));

    return (
      <select id={styles.main} className={visible ? "" : styles.hidden} value={navigation.selected} onChange={onChange}>
        {options}
      </select>
    );
	}
}

export default SelectComponent;
