// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#000;margin:0}#admin__main__CSUpI5pABHZvTkq5sHDW{color:#000}#admin__main__CSUpI5pABHZvTkq5sHDW>div{padding:5px}#admin__main__CSUpI5pABHZvTkq5sHDW table,th,td{border:1px solid;border-spacing:0}.admin__activate__r4ldNKE9iCYPRVPfXwpT{background-color:green}.admin__deactivate__gHdMs4qkJ9LWV_ZqKI7m{background-color:red}", "",{"version":3,"sources":["webpack://./src/main/typescript/components/globals.scss","webpack://./src/main/typescript/components/app/admin/style/admin.scss"],"names":[],"mappings":"AAUA,KACE,UAJa,CAKb,QAAA,CCKF,mCACE,UAVQ,CAaV,uCACE,WAAA,CAGF,+CACE,gBAAA,CACA,gBAAA,CAGF,uCACE,sBAAA,CAGF,yCACE,oBAAA","sourcesContent":["\n$debug: false;\n\n$body-color: null;\n@if ($debug) {\n  $body-color: red;\n} @else {\n  $body-color: black;\n}\n\nbody {\n  color: $body-color;\n  margin: 0;\n}\n","\n@import \"../../../globals.scss\";\n\n\n$color: null;\n@if ($debug) {\n  $color: red;\n} @else {\n  $color: black;\n}\n\n\n:export {\n  textColor: $color;\n}\n\n\n#main {\n  color: $color;\n}\n\n#main > div {\n  padding: 5px;\n}\n\n#main table, th, td {\n  border: 1px solid;\n  border-spacing: 0;\n}\n\n.activate {\n  background-color: green;\n}\n\n.deactivate {\n  background-color: red;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textColor": "#000",
	"main": "admin__main__CSUpI5pABHZvTkq5sHDW",
	"activate": "admin__activate__r4ldNKE9iCYPRVPfXwpT",
	"deactivate": "admin__deactivate__gHdMs4qkJ9LWV_ZqKI7m"
};
export default ___CSS_LOADER_EXPORT___;
