
import * as React from "react";

import { AuthResources, AuthView } from "./Auth";

type LoginProperties = {
  language: AuthView['language']
  resources: AuthResources
}

type LoginState = {
}

class Login extends React.Component<LoginProperties, LoginState> {

  constructor(props: LoginProperties) {
		super(props);
		this.state = {};
  }

  render() {
    const { language, resources } = this.props;

		return (
			<div id="login">
        <label>{resources.messages[language.type]["auth.login.form.username.label"]?.value}{" "}</label>
        <input type="text" id="username" name="username" />
        <br/>
        <label>{resources.messages[language.type]["auth.login.form.password.label"]?.value }{" "}</label>
        <input type="password" id="password" name="password" />
			</div>
		)
	}
}

export default Login;
