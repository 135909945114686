import * as React from "react";

import { ApplicationProperties } from "../../types/chi";
import { ApplicationView } from "./App";
import styles from "./style/logout.scss";

type LogoutProperties = {
  csrf: ApplicationProperties.Csrf
  view: ApplicationView
}

class LogoutComponent extends React.Component<LogoutProperties> {
  render() {
    const { csrf, view } = this.props;

		return (
			<div id={styles.main} className={view.collapsed ? styles.collapsed : ""}>
        <div>
          <form action="logout" method="post">
            <input type="hidden" name={csrf.name} value={csrf.value} />
            <button type="submit">Log Out</button>
          </form>
        </div>
			</div>
		)
	}
}

export default LogoutComponent;
