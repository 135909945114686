
import * as React from "react";

import { ApplicationProperties, Revision, RevisionType } from "../../../types/chi";
import { CrudResource } from "../../../utils/HateoasUtils";
import { StatusType } from "./Revisions";

type RevisionProperties = {
  csrf: ApplicationProperties.Csrf
  revision: CrudResource<Revision>
  updateRevisionStatus: (message: string, status: StatusType) => void
  checkRevisionStatus: (message: string, onComplete: () => void) => void
  refreshRevisionStatus: () => void
  refreshView: () => void
  status: StatusType
};

type RevisionState = {
  revision: Revision
}

class RevisionComponent extends React.Component<RevisionProperties, RevisionState> {

  constructor(props: RevisionProperties) {
		super(props);

    this.state = { revision: props.revision };
  }

  private onClickLoad = async (event: React.MouseEvent<HTMLButtonElement>) => {
    const { revision, csrf, refreshView, updateRevisionStatus, checkRevisionStatus } = this.props;

    event.stopPropagation();
    event.preventDefault();

    const load : boolean = event.currentTarget.value == "Load";

    updateRevisionStatus(`${load ? "Loading" : "Unloading"} revision ${revision.name}`, StatusType.BUSY);

    fetch(`api/revisions/${revision.id}`, {
      method: "PATCH",
      headers: new Headers({
        "content-type": "application/json-patch+json",
        "x-csrf-token": csrf.value
      }),
      mode: "cors",
      body: JSON.stringify([
        { "op": "replace", "path": "/loaded", "value": load },
      ])
    })
    .then(response => {
      revision.loaded = load;
      this.setState({revision});
      checkRevisionStatus(`Finished ${load ? "loading" : "unloading"} revision ${revision.name}`, () => refreshView());
    })
    .catch(errorData => console.error("errorData", errorData));
  }


  private onClickPublish = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { revision, csrf, refreshView, refreshRevisionStatus, updateRevisionStatus, checkRevisionStatus } = this.props;

    event.stopPropagation();
    event.preventDefault();

    updateRevisionStatus(`Publishing revision ${revision.name}`, StatusType.BUSY);

    fetch(`api/revisions/${revision.id}`, {
      method: "PATCH",
      headers: new Headers({
        "content-type": "application/json-patch+json",
        "x-csrf-token": csrf.value
      }),
      mode: "cors",
      body: JSON.stringify([
        { "op": "replace", "path": "/published", "value": "true" },
      ])
    })
    .then(response => {
      revision.published = true;
      this.setState({revision});
      checkRevisionStatus(`Finished publishing revision ${revision.name}`, () => {
        refreshView();
        refreshRevisionStatus();
      });
    })
    .catch(errorData => console.error("errorData", errorData))
  }

  private loadButton = (revision: Revision, status: StatusType) =>
    !revision.published
    ?
    <button
      value={revision.loaded ? "Unload" : "Load" }
      onClick={this.onClickLoad}
      disabled={status === StatusType.BUSY}
    >
      {revision.loaded ? "Unload" : "Load" }
    </button>
    :
    null

  private publishButton = (revision: Revision, status: StatusType) =>
    revision.loaded
    ?
    <button
      value="Publish"
      onClick={this.onClickPublish}
      hidden={revision.type == RevisionType.FS}
      disabled={status === StatusType.BUSY}
    >
      Publish
    </button>
    :
    null

  render() {
    const { revision } = this.state;
    const { status } = this.props;

		return (
      <tr>
        <td>{revision.name}</td>
        <td>{revision.type}</td>
        <td>{revision.ref}</td>
        <td>{revision.loaded ? "Yes" : "No" }{this.loadButton(revision, status)}</td>
        <td>{revision.published ? "Yes" : "No" }{this.publishButton(revision, status)}</td>
      </tr>
		)
	}
}

export default RevisionComponent;
