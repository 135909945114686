
export const useExternalScript = (url: string) => {
  if (!url) {
    return;
  }
  let script = <HTMLScriptElement>document.querySelector(`script[src="${url}"]`);

  if (!script) {
    script = document.createElement("script");
    script.type = "application/javascript";
    script.src = url;
    script.async = true;
    document.body.appendChild(script);
  }
};