
import * as React from "react";

import * as ScriptUtils from "../../utils/ScriptUtils";

import { AuthComponent, AuthResources, AuthView } from "./Auth";

type PwlostProperties = {
  validateInput: (element?: HTMLInputElement) => void;
  language: AuthView['language']
  resources: AuthResources
}

class Pwlost extends React.Component<PwlostProperties> {

  private onChange = (e: React.FormEvent<HTMLInputElement>) => this.props.validateInput(e.currentTarget)

  render() {
    const { language, resources } = this.props;
    const { onChange } = this;

    ScriptUtils.useExternalScript("https://www.google.com/recaptcha/api.js");

    return (
      <div id="signup">
      <label>{resources.messages[language.type]["auth.pwlost.form.email.label"].value}{" "}</label>
      <input type="text" id="email" name="email" onChange={onChange} />
      </div>
      )
    }
  }

  export default Pwlost;
