import * as React from "react";

import styles from "./style/iframe.scss";

type IframeProperties = {
  url: string;
};

class IframeComponent extends React.Component<IframeProperties> {
  render() {
    const { url } = this.props;

		return (
			<div id={styles.main}>
        <iframe src={url} title="iframe" seamless/>
			</div>
		)
	}
}

export default IframeComponent;
