import * as React from "react";

import { Book, Chapter, Section, ApplicationProperties, PageType, RoleType } from "../../types/chi";
import { ApplicationResources, ApplicationView } from "./App";
import LanguageComponent from "./Language";
import LogoComponent from "./Logo";
import LogoutComponent from "./Logout";
import SelectComponent from "./Select";
import TreeComponent from "./Tree";
import styles from "./style/navigation.scss";

export type NavigationItem = {
  id: number;
  name: string;
  items?: NavigationItem[];
}

export type NavigationSelected = string;

export type Navigation = {
  parts: { [page in PageType]: NavigationItem[] }
  selected: NavigationSelected
}

type NavigationProperties = {
  csrf: ApplicationProperties.Csrf
  user: ApplicationProperties.User
  view: ApplicationView
  resources: ApplicationResources
  changeView: (view: Partial<ApplicationView>) => void
}

class NavigationComponent extends React.Component<NavigationProperties> {

  private isBook(book: any): book is Book {
    return book['chapters'] !== undefined;
  }

  private isAdmin(user: ApplicationProperties.User): boolean {
    return user.role == RoleType.ADMIN;
  }

  private createNavigationItem(items: (Book | Chapter | Section)[] = null): NavigationItem[] {
    const { user } = this.props;
    return items ? items.map(item => ({
      id: item.id,
      name: this.isBook(item) && this.isAdmin(user) ? `${item.name} (${item.revision.name})` : item.name,
      items: this.createNavigationItem((item as Book).chapters || (item as Chapter).sections)
    })) : null;
  }

  private createNavigation() : Navigation {
    const { resources, view, user } = this.props;

    const filteredBooks = resources.books.filter(book => book.language.type == view.language.type);

    const navigation : Navigation = {
      parts: {
        [PageType.NONE]: [],
        [PageType.BOOK]: [],
        [PageType.USER]: [],
        [PageType.ADMIN]: []
      },
      selected: `${view.page}_1`
    };

    if (filteredBooks.length > 0) {
      navigation.parts[PageType.BOOK] = this.createNavigationItem(filteredBooks);
    }
    navigation.parts[PageType.USER] = [{ id: 1, name: resources.messages[view.language.type]["app.user.navigation"]?.value ?? "User"}];
    if (user.role == RoleType.ADMIN) {
      navigation.parts[PageType.ADMIN] = [
        { id: 1, name: "Admin", items:
          [
            {id: 1, name: "Users"},
            {id: 2, name: "Revisions"}
          ]
        }
      ]
    }
    if (view.page == PageType.BOOK) {
      if (view.book.chapters.find(chapter => chapter.id == view.chapter.id).sections.length > 0) {
        navigation.selected = `${view.page}_${view.book.id}_${view.chapter.id}_${view.section.id}`;
      } else {
        navigation.selected = `${view.page}_${view.book.id}_${view.chapter.id}`;
      }
    }
    return navigation
  }

  private changeCollapsed = (collapsed: ApplicationView['collapsed']) => {
    const { view, changeView } = this.props;

    view.collapsed = collapsed;

    changeView(view);
  }

  private changeLanguage = (language: ApplicationView['language']) => {
    const { resources, view, changeView } = this.props;

    view.language = language;

    if (view.page == PageType.BOOK) {
      view.book = resources.books.find(book => book.name == view.book.name && book.language.type == language.type);
      view.chapter = view.book.chapters.find(chapter => chapter.id == view.chapter.id);
      view.section = view.chapter.sections.find(section => section.id == view.section.id) || { id: 0 } as Section;   
    }

    changeView(view);
  }

  private changeContent = (tag: string) => {
    const { resources, view, changeView } = this.props;

    const tags = tag.split("_");

    if (tags.length > 0) {
      view.page = tags[0] as PageType;
    }

    if (view.page == PageType.ADMIN) {
      if (tags[2] == "1") {
        view.admin = "Users";
      }
      else if(tags[2] == "2") {
        view.admin = "Revisions";
      }
    }

    if (view.page == PageType.BOOK && tags.length > 1) {
      view.book = resources.books.find(book => book.id == Number(tags[1]));
    } else {
      view.book = resources.books[0];
    }

    if (view.page == PageType.BOOK && tags.length > 2) {
      view.chapter = view.book.chapters.find(chapter => chapter.id == Number(tags[2]));
    }

    if (view.page == PageType.BOOK && tags.length > 3) {
      view.section = view.chapter.sections.find(section => section.id == Number(tags[3]));
    }

    changeView(view);
  }

  render() {
    const { resources, view, csrf } = this.props;
    const { changeCollapsed, changeContent, changeLanguage } = this;

    const navigation: Navigation = this.createNavigation();

    return (
      <div id={styles.main} className={ view.collapsed ? styles.collapsed : ""}>
        <LogoComponent view={view} changeCollapsed={changeCollapsed} />

        <div id={styles.toolbar} className={ view.collapsed ? styles.collapsed : ""}>
          <LanguageComponent csrf={csrf} view={view} resources={resources} changeLanguage={changeLanguage} />
          <LogoutComponent csrf={csrf} view={view} />
        </div>

        <div id={styles.content} className={ view.collapsed ? styles.collapsed : ""}>
          <SelectComponent navigation={navigation} visible={view.collapsed} changeContent={changeContent}/>
          <TreeComponent navigation={navigation} visible={!view.collapsed} changeContent={changeContent}/>
        </div>
      </div>
    );
	}
}

export default NavigationComponent;
