// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#000;margin:0}#content__main__Ep6vVDj3FM7wTijjU2YA{flex:1;color:#000;overflow-y:auto}", "",{"version":3,"sources":["webpack://./src/main/typescript/components/globals.scss","webpack://./src/main/typescript/components/app/style/content.scss"],"names":[],"mappings":"AAUA,KACE,UAJa,CAKb,QAAA,CCIF,qCACE,MAAA,CACA,UAXQ,CAaR,eAAA","sourcesContent":["\n$debug: false;\n\n$body-color: null;\n@if ($debug) {\n  $body-color: red;\n} @else {\n  $body-color: black;\n}\n\nbody {\n  color: $body-color;\n  margin: 0;\n}\n","@import \"../../globals.scss\";\n\n\n$color: null;\n@if ($debug) {\n  $color: red;\n} @else {\n  $color: black;\n}\n\n\n:export {\n  textColor: $color;\n}\n\n\n#main {\n  flex: 1;\n  color: $color;\n\n  overflow-y: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textColor": "#000",
	"main": "content__main__Ep6vVDj3FM7wTijjU2YA"
};
export default ___CSS_LOADER_EXPORT___;
