// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#000;margin:0}#select__main__jezDyL8QbzdcfmLczfZ5{color:#1c87c9;width:100%;border:1px solid #e6e6e6;font-size:16px;background-color:#fafafa;border-radius:5px;box-shadow:2px 2px #ccc}#select__main__jezDyL8QbzdcfmLczfZ5.select__hidden__MrDAfPaj_fOlspYd942L{display:none}#select__main__jezDyL8QbzdcfmLczfZ5:hover{box-shadow:0 0 0 3px #0a83d978}", "",{"version":3,"sources":["webpack://./src/main/typescript/components/globals.scss","webpack://./src/main/typescript/components/app/style/select.scss"],"names":[],"mappings":"AAUA,KACE,UAJa,CAKb,QAAA,CCFF,oCACE,aAJQ,CAKR,UAAA,CAEA,wBAAA,CACA,cAAA,CACA,wBAAA,CACA,iBAAA,CACA,uBAAA,CAGF,yEACE,YAAA,CAGF,0CACE,8BACE","sourcesContent":["\n$debug: false;\n\n$body-color: null;\n@if ($debug) {\n  $body-color: red;\n} @else {\n  $body-color: black;\n}\n\nbody {\n  color: $body-color;\n  margin: 0;\n}\n","\n@import \"../../globals.scss\";\n\n$color: null;\n@if ($debug) {\n  $color: red;\n} @else {\n  $color: #1c87c9;//black;\n}\n\n#main {\n  color: $color;\n  width: 100%;\n\n  border: 1px solid #e6e6e6;\n  font-size: 16px;\n  background-color: #fafafa;\n  border-radius: 5px;\n  box-shadow: 2px 2px #ccc;\n}\n\n#main.hidden {\n  display: none;\n}\n\n#main:hover {\n  box-shadow:\n    0 0 0 3px #0a83d978;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "select__main__jezDyL8QbzdcfmLczfZ5",
	"hidden": "select__hidden__MrDAfPaj_fOlspYd942L"
};
export default ___CSS_LOADER_EXPORT___;
