import { ApplicationProperties, Language, LanguageType, Message } from "../../types/chi"
import { CrudRepository, CrudResource } from "../../utils/HateoasUtils"


export type ApplicationLanguages = {
  [langauge in LanguageType]: Language
}

class LanguageUtils {
  public static createApplicationLanguages = () =>
    fetch("api/languages")
      .then(response => response.json())
      .then((repository: CrudRepository<Language>) => {
        const languages: ApplicationLanguages = {
          "DE": null,
          "EN": null,
          "FR": null,
        };

        return Promise.all(
          (repository._embedded.languages)
            .map((resource: CrudResource<Language>) => fetch(resource._links.self.href)
            .then(response => response.json())
            .then((language: Language) => languages[language.type] = language))
        )
        .then(_ => languages);
      });

  public static setApplicationLanguage = (language: Language, csrf: ApplicationProperties.Csrf) => {
    const formData = new FormData();
    formData.append(csrf.name, csrf.value);
    formData.append("value", language.type);

    return fetch("api/auth/language", {
      method: "POST",
      mode: "cors",
      body: formData
    })
    .catch(errorData => console.error("errorData", errorData));
  }
}

export default LanguageUtils;