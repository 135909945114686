// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#000;margin:0}#iframe__main__YmzE_gbSdLiJDz_1awLK{color:#000;height:100%;line-height:0}#iframe__main__YmzE_gbSdLiJDz_1awLK>iframe{width:100%;height:100%;color:#000;border:0}", "",{"version":3,"sources":["webpack://./src/main/typescript/components/globals.scss","webpack://./src/main/typescript/components/app/iframe/style/iframe.scss"],"names":[],"mappings":"AAUA,KACE,UAJa,CAKb,QAAA,CCEF,oCACE,UARQ,CASR,WAAA,CAGA,aAAA,CAGF,2CACE,UAAA,CACA,WAAA,CACA,UAlBQ,CAmBR,QAAA","sourcesContent":["\n$debug: false;\n\n$body-color: null;\n@if ($debug) {\n  $body-color: red;\n} @else {\n  $body-color: black;\n}\n\nbody {\n  color: $body-color;\n  margin: 0;\n}\n","\n@import \"../../../globals.scss\";\n\n$color: null;\n@if ($debug) {\n  $color: red;\n} @else {\n  $color: black;\n}\n\n:export {\n  textColor: $color;\n}\n\n#main {\n  color: $color;\n  height: 100%;\n\n  // https://stackoverflow.com/questions/9129182/iframe-100-height-causes-vertical-scrollbar\n  line-height: 0;\n}\n\n#main > iframe {\n  width: 100%;\n  height: 100%;\n  color: $color;\n  border: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"textColor": "#000",
	"main": "iframe__main__YmzE_gbSdLiJDz_1awLK"
};
export default ___CSS_LOADER_EXPORT___;
