import * as React from "react";

import { ApplicationProperties, LanguageType } from "../../types/chi";
import LanguageUtils from "../shared/Languages";
import { ApplicationResources, ApplicationView } from "./App";
import styles from "./style/language.scss";

type LanguageProperties = {
  resources: ApplicationResources
  view: ApplicationView
  csrf: ApplicationProperties['csrf']
  changeLanguage: (language: ApplicationView['language']) => void;
}

class LanguageComponent extends React.Component<LanguageProperties> {
  private onClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { csrf, resources, changeLanguage } = this.props;

    event.preventDefault();

    const language = resources.languages[event.currentTarget.value as LanguageType];

    changeLanguage(language);

    LanguageUtils.setApplicationLanguage(language, csrf);
  }

  render() {
    const { view, resources } = this.props;
    const { onClick } = this;

    const languages = resources.languages;

    const languageMessageButtons = <div>
                                     {Object.keys(languages)
                                            .map((language: LanguageType) =>
                                              <span key={language}>
                                                <button
                                                  onClick={onClick}
                                                  key={language}
                                                  value={language}
                                                  hidden={view.language.type == language}
                                                >
                                                  {languages[language]?.name}
                                                </button>
                                                {" "}
                                              </span>
                                            )
                                     }
                                   </div>

		return (
			<div id={styles.main} className={view.collapsed ? styles.collapsed : ""}>
        {languageMessageButtons}
			</div>
		)
	}
}

export default LanguageComponent;
