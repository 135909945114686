// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#000;margin:0}.tree__main__oYy_maPIBP_I8qmsYiX0{color:#1c87c9}.tree__main__oYy_maPIBP_I8qmsYiX0 span{cursor:pointer}.tree__hidden__zczSX9Op2zsZTFngBaOM{display:none}ul{list-style:none;padding:0}li{padding-left:1.1em}span.tree__bold__MfekQ3Po5xfHe8oxZbsA{font-weight:bold}", "",{"version":3,"sources":["webpack://./src/main/typescript/components/globals.scss","webpack://./src/main/typescript/components/app/style/tree.scss"],"names":[],"mappings":"AAUA,KACE,UAJa,CAKb,QAAA,CCFF,kCACE,aAJQ,CAOV,uCACE,cAAA,CAGF,oCACE,YAAA,CAIF,GACE,eAAA,CACA,SAAA,CAGF,GACE,kBAAA,CAGF,sCACE,gBAAA","sourcesContent":["\n$debug: false;\n\n$body-color: null;\n@if ($debug) {\n  $body-color: red;\n} @else {\n  $body-color: black;\n}\n\nbody {\n  color: $body-color;\n  margin: 0;\n}\n","\n@import \"../../globals.scss\";\n\n$color: null;\n@if ($debug) {\n  $color: red;\n} @else {\n  $color: #1c87c9;//black;\n}\n\n.main {\n  color: $color;\n}\n\n.main span {\n  cursor: pointer;\n}\n\n.hidden {\n  display: none;\n}\n\n\nul {\n  list-style: none;\n  padding: 0;\n}\n\nli {\n  padding-left: 1.1em;\n}\n\nspan.bold {\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "tree__main__oYy_maPIBP_I8qmsYiX0",
	"hidden": "tree__hidden__zczSX9Op2zsZTFngBaOM",
	"bold": "tree__bold__MfekQ3Po5xfHe8oxZbsA"
};
export default ___CSS_LOADER_EXPORT___;
