import * as React from "react";

import { PageType, ApplicationProperties } from "../../types/chi";
import { ApplicationResources, ApplicationView } from "./App";
import Iframe from "./iframe/Iframe";
import User from "./user/User";
import Admin from "./admin/Admin";
import styles from "./style/content.scss";

type ContentProperties = {
  resources: ApplicationResources
  csrf: ApplicationProperties.Csrf
  user: ApplicationProperties.User
  view: ApplicationView
  refreshView: () => void
}

class ContentComponent extends React.Component<ContentProperties> {
  render() {
    const { csrf, user, view, resources, refreshView } = this.props;

    let element : React.ReactElement;
    let url: string;

    switch(view.page) {
      case PageType.ADMIN:
        element = <Admin user={user} csrf={csrf} page={view.admin} refreshView={refreshView}/>;
        break;
      case PageType.USER:
        element = <User user={user} csrf={csrf} resources={resources} language={view.language} />;
        break;
      case PageType.BOOK:
        url = `api/books/${view.book.id}/chapters/${view.chapter.id}/content`;
        if (view.section.href) {
          url += `#${view.section.href}`;
        }
        element = <Iframe url={url} />;
        break;
      default:
        element = null;
        break;
    }

		return (
			<div id={styles.main} style={{borderColor: styles.textColor}}>
        {element}
			</div>
		)
	}
}

export default ContentComponent;
