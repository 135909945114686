import * as React from "react";

import { ApplicationProperties, PageType, Language, Book, Section, Chapter } from "../../types/chi";
import { CrudRepository } from "../../utils/HateoasUtils";
import MessageUtils, { ApplicationMessages } from "../shared/Messages";
import LanguageUtils, { ApplicationLanguages } from "../shared/Languages";
import Navigation from "./Navigation";
import Content from "./Content";
import styles from "./style/application.scss";

export type ApplicationView = {
  admin: "Users" | "Revisions"
  page: PageType
  language: Language
  book: Book,
  chapter: Chapter,
  section: Section,
  collapsed: boolean
}

export type ApplicationResources = {
  books: Book[]
  messages: ApplicationMessages
  languages: ApplicationLanguages
}

type ApplicationState = {
  view: ApplicationView
  resources: ApplicationResources
}

class AppComponent extends React.Component<ApplicationProperties, ApplicationState> {

  constructor(props: ApplicationProperties) {
		super(props);

    this.state = {
      view: {
        admin: null,
        page: PageType.NONE,
        language: this.props.user.language,
        book: null,
        chapter: null,
        section: null,
        collapsed: true
      },
      resources: {
        books: [],
        languages: {
          "DE": null,
          "EN": null,
          "FR": null,
        },
        messages: {
          "DE": {},
          "EN": {},
          "FR": {},
        }
      },
    };
  }

  componentDidMount() {
    const { resources } = this.state;

    window.onpopstate = (event: PopStateEvent) => this.setView(event.state, false);

    Promise.all([
      MessageUtils.createApplicationMessages().then(messages => resources.messages = messages),
      LanguageUtils.createApplicationLanguages().then(languages => resources.languages = languages),
      fetch("api/books")
      .then(response => response.json())
      .then((repository: CrudRepository<Book>) => Promise.all(
        (repository._embedded.books)
          .map(book => fetch(book._links.chapters.href)
            .then(response => response.json())
            .then(chapters => {
              resources.books.push({
                id: book.id,
                name: book.name,
                language: book.language,
                revision: book.revision,
                chapters: chapters._embedded.chapters
              });
            }))
        )
      )
      .then(_ => {
        let { page } = this.props.application;
        let { language } = this.props.user;


        const hasView = new URLSearchParams(window.location.search).get("view");
        if (hasView) {
          const parsedContent: ApplicationView = JSON.parse(decodeURIComponent(hasView));
          language = parsedContent.language;
          page = parsedContent.page;
        }

        const book: Book = resources.books.find(b => b.language.type == language.type);

        this.setState({view: {
          admin: null,
          page,
          language,
          book,
          chapter: book.chapters[0],
          section: book.chapters[0].sections[0],
          collapsed: true
        }});
      })
    ])
    .then(_ => this.setState({ resources }));
  }

  private setView(view: Partial<ApplicationView>, pushState: boolean) {
    const { admin, page, language, book, chapter, section, collapsed } = this.state.view;

    this.setState({ view: {
      admin: view.admin ??  admin,
      page: view.page ?? page,
      language: view.language ?? language,
      book: view.book ?? book,
      chapter: view.chapter ?? chapter,
      section: view.section ?? section,
      collapsed: view.collapsed ?? collapsed
    } }, () => {
      if (!pushState) {
        return;
      }

      const url = new URL(window.location.toString());
      window.history.pushState(this.state.view, null, url);
    });
  }

  private reloadResources(resource: Partial<keyof ApplicationResources>) {
    const { resources } = this.state;

    if (resource !== "books") {
      return;
    }

    const availableBooks: number[] = [];

    fetch("api/books")
    .then(response => response.json())
    .then((repository: CrudRepository<Book>) => Promise.all(
      !repository.hasOwnProperty("_embedded")
      ?
      []
      :
      repository._embedded.books
        .map(book => {
          availableBooks.push(book.id);
          if (resources.books.find(b => b.id == book.id)) {
            return;
          }

          return fetch(book._links.chapters.href)
          .then(response => response.json())
          .then(chapters => {
            resources.books.push({
              id: book.id,
              name: book.name,
              language: book.language,
              revision: book.revision,
              chapters: chapters._embedded.chapters
            });
          })
        })
      )
    )
    .then(_ => {
      let books: Book[] = [];
      for (let book of resources.books) {
        if (availableBooks.indexOf(book.id) > -1) {
          books.push(book);
        }
      }
      resources.books = books;

      this.setState({ resources });
    });

  }

  private changeView = (view: Partial<ApplicationView>) => this.setView(view, true);

  private refreshView = () => this.reloadResources("books");

  render() {
    const { csrf, user } = this.props;
    const { view, resources } = this.state;
    const { changeView, refreshView } = this;

    return (
			<div id={styles.main} className={view.collapsed ? styles.collapsed : ""}>
        <Navigation
          csrf={csrf}
          user={user}
          view={view}
          resources={resources}
          changeView={changeView}
        />
        <Content
          user={user}
          view={view}
          csrf={csrf}
          resources={resources}
          refreshView={refreshView}
        />
      </div>
		)
	}
}


export default AppComponent;
