import { Book, Language, License, Message, Revision, User } from "../types/chi";
import { LinkedResource, EmbeddedResource } from "../types/hateoas";

type ResourceName<Type> =
  Type extends Book ? "books" :
  Type extends Language ? "languages" :
  Type extends License ? "licenses" :
  Type extends Message ? "messages" :
  Type extends User ? "users" :
  Type extends Revision ? "revisions" :
  never;

type LinkNames<Type> =
  Type extends Book ? "self" | "chapters" :
  Type extends User ? "self" | "license" | "language" :
  Type extends License ? "self" :
  Type extends Language ? "self" :
  Type extends Revision ? "self" :
  never;

export type CrudResource<Type> = Type & LinkedResource<LinkNames<Type>>

export type CrudRepository<Type> = EmbeddedResource<Type, ResourceName<Type>, LinkNames<Type>>

export type PagedRestRepositoryLinkNames = "first" | "prev" | "next" | "last"

export function isPagedRestRepositoryLink(key: string): key is PagedRestRepositoryLinkNames {
  return key === "first" || key === "prev" || key === "next" || key === "last";
}

export type PagingAndSortingRepository<Type> = CrudRepository<Type> & LinkedResource<PagedRestRepositoryLinkNames>
