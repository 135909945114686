import { LanguageType, Message } from "../../types/chi"
import { CrudRepository } from "../../utils/HateoasUtils"


export type ApplicationMessages = {
  [langauge in LanguageType]: {
    [key: string]: Message
  }
}

class MessageUtils {
  public static createApplicationMessages = () =>
    fetch("api/messages")
      .then(response => response.json())
      .then((repository: CrudRepository<Message>) => {
        const messages: ApplicationMessages = {
          "DE": {},
          "EN": {},
          "FR": {},
        };

        for (const message of repository._embedded.messages) {
          messages[message.language.type][message.key] = message;
        }

        return messages
      });
}

export default MessageUtils;