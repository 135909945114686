/// <reference path = "types/globals.d.ts" />

import * as React from "react";
import * as ReactDOM from "react-dom";

import App from "./components/app/App";
import Auth from "./components/auth/Auth";
import { ApplicationProperties, ComponentType } from "./types/chi";

declare global { let applicationProperties: ApplicationProperties }

document.addEventListener("DOMContentLoaded", _ => {
  console.info(`${applicationProperties.application.name} ${applicationProperties.application.version} (${process.env.NODE_ENV})`);

  window.addEventListener("load", () => {
    if (!("serviceWorker" in navigator)) {
      console.warn("Service Worker is not supported by your browser.");
    } else {
      if (applicationProperties.service.worker) {
        navigator.serviceWorker.register("service-worker.js")
          .then((registration) => console.info("SW registered: ", registration.scope))
          .catch((err) => console.error("SW registration failed: ", err));
      } else {
        navigator.serviceWorker.getRegistrations()
          .then((registrations) => {
            for(let registration of registrations) {
              registration.unregister();
              console.info("SW unregistered: ", registration.scope);
            }
          })
          .catch((err) => console.error("SW unregistration failed: ", err));
      }
    }
  });

  const container: HTMLElement = document.getElementById("main");

  let component: React.ReactElement;
  switch(applicationProperties.application.component) {
    case ComponentType.AUTHENTICATION:
      component = React.createElement(Auth, applicationProperties);
      break;
    case ComponentType.APPLICATION:
    default:
      component = React.createElement(App, applicationProperties);
      break;
  }

  ReactDOM.render(component, container);
});
