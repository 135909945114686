
import * as React from "react";

import { ApplicationProperties, Revision, RevisionStatus } from "../../../types/chi";
import { CrudResource, isPagedRestRepositoryLink, PagingAndSortingRepository } from "../../../utils/HateoasUtils";
import RevisionComponent from "./Revision";
import styles from "./style/admin.scss";

type RevisionsProperties = {
  csrf: ApplicationProperties.Csrf
  refreshView: () => void
};

type RevisionsState = {
  appVersion: string
  revisions: CrudResource<Revision>[]
  links: PagingAndSortingRepository<Revision>["_links"]
  status: StatusType
}

export enum StatusType {
  IDLE,
  BUSY
}

class RevisionsComponent extends React.Component<RevisionsProperties, RevisionsState> {

  private revisionStatusTextArea: HTMLTextAreaElement;

  constructor(props: RevisionsProperties) {
		super(props);
    this.state = {
      appVersion: "",
      revisions: [],
      links: {},
      status: StatusType.IDLE
    };
  }

  componentDidMount(): void {
    this.fetchRevisions();
  }

  private fetchRevisions(url?: string, page: number = 0, size: number = 20) {
    const { links } = this.state;

    let { revisions, appVersion } = this.state;

    if (!url) {
      url = `/api/revisions?page=${page}&size=${size}`;
    }

    revisions.length = 0;
    for (const key in links) {
      if (isPagedRestRepositoryLink(key)) {
        delete links[key];
      }
    }

    Promise.all([
      fetch(url)
      .then(response => response.json())
      .then((repository: PagingAndSortingRepository<Revision>) => {
        for (const key in repository._links) {
          if (isPagedRestRepositoryLink(key)) {
            links[key] = repository._links[key];
          }
        }

        return Promise.all(
        (repository._embedded.revisions)
          .map(resource => fetch(resource._links.self.href)
            .then(response => response.json())
            .then(revision => revisions.push(revision)))
        )
      })
      .then(_ => {
        revisions.sort((a, b) => (a.id - b.id));
      }),
      fetch(`/api/revisions/status`)
      .then(response => response.json())
      .then((revision: RevisionStatus) => {
        appVersion = revision.appVersion;
      })
    ]).then(_ => {
      this.setState({ revisions, appVersion });
    });
  }

  private updateRevisionStatus = (message: string, status?: StatusType) => {
    this.revisionStatusTextArea.value += `[${new Date().toLocaleString()}] ${message}\n`;
    this.revisionStatusTextArea.scrollTop = this.revisionStatusTextArea.scrollHeight;

    if (status !== undefined) {
      this.setState({ status });
    }
  }

  private checkRevisionStatus = (message: string, onComplete?: () => void) => {
    fetch(`/api/revisions/status`)
    .then(response => response.json())
    .then((revision: RevisionStatus) => {
      if (revision.status != "IDLE") {
        setTimeout(() => this.checkRevisionStatus(message, onComplete), 500);
      } else {
        this.updateRevisionStatus(message, StatusType.IDLE);

        if (onComplete !== undefined) {
          onComplete();
        }
      }
    });
  }

  private refreshRevisionStatus = () => {
    const { csrf } = this.props;

    this.updateRevisionStatus("Updating revisions", StatusType.BUSY);

    fetch(`api/revisions/status`, {
      method: "POST",
      headers: new Headers({
        "content-type": "application/json-patch+json",
        "x-csrf-token": csrf.value
      }),
      mode: "cors",
    })
    .then(response => {
      this.checkRevisionStatus("Finished updating revisions", () => {
        this.fetchRevisions()
        this.forceUpdate();
      });
    })
    .catch(errorData => console.error("errorData", errorData));
  }

  private onClickRefresh = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    event.preventDefault();
    this.refreshRevisionStatus();
  }

  render() {
    const { csrf, refreshView } = this.props;
    const { revisions, links, status, appVersion } = this.state;

		return (
			<div id={styles.main}>
        <label htmlFor="appVersion">Application Version:</label>
        <input type="text" id="appVersion" disabled={true} value={appVersion}></input>
        &nbsp;
        <button value="Refresh" onClick={this.onClickRefresh} disabled={status === StatusType.BUSY}>Refresh</button>
        <br/>
        <textarea ref={(c) => this.revisionStatusTextArea = c} rows={20} cols={80}></textarea>
        <br/>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Ref</th>
              <th>Loaded</th>
              <th>Published</th>
            </tr>
          </thead>
          <tbody>
            {revisions.map(
              revision => <RevisionComponent
                            key={(revision as any)._links.self.href}
                            revision={revision}
                            updateRevisionStatus={this.updateRevisionStatus}
                            checkRevisionStatus={this.checkRevisionStatus}
                            csrf={csrf}
                            refreshRevisionStatus={this.refreshRevisionStatus}
                            refreshView={refreshView}
                            status={status}
                          />
            )}
          </tbody>
        </table>
        <div>
          <button key="first" onClick={() => this.fetchRevisions(links["first"].href)} disabled={!("first" in links)}>&lt;&lt;</button>
          <button key="prev" onClick={() => this.fetchRevisions(links["prev"].href)} disabled={!("prev" in links)}>&lt;</button>
          <button key="next" onClick={() => this.fetchRevisions(links["next"].href)} disabled={!("next" in links)}>&gt;</button>
          <button key="last" onClick={() => this.fetchRevisions(links["last"].href)} disabled={!("last" in links)}>&gt;&gt;</button>
        </div>
			</div>
		)
	}
}

export default RevisionsComponent;
