import * as React from "react";

import { ApplicationProperties } from "../../../types/chi";
import { ApplicationView } from "../App";
import RevisionsComponent from "./Revisions";
import UsersComponent from "./Users";
import styles from "./style/admin.scss";

type AdminProperties = {
  csrf: ApplicationProperties.Csrf
  user: ApplicationProperties.User
  page: ApplicationView["admin"]
  refreshView: () => void
};

class AdminComponent extends React.Component<AdminProperties> {

  render() {
    const { csrf, user, page, refreshView } = this.props;

    let element : React.ReactElement;
    switch(page) {
      case "Users":
        element = <UsersComponent csrf={csrf} />
        break;
      case "Revisions":
        element = <RevisionsComponent csrf={csrf} refreshView={refreshView}/>
        break;
    }

		return (
			<div id={styles.main}>
        <div><h3>{page}</h3></div>
        <div>You are logged in as {user.name} ({user.role}) to the administrative interface.</div>

        {element}
			</div>
		)
	}
}

export default AdminComponent;
