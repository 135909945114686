// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "body{color:#000;margin:0}html,body,body>div:first-of-type,#application__main__lVn1OWdchMCeTv9Bl62w{width:100vw;height:calc(100vh - (100vh - 100%))}#application__main__lVn1OWdchMCeTv9Bl62w{display:flex}#application__main__lVn1OWdchMCeTv9Bl62w.application__collapsed__H8MTHKOGMbF91cIwAwan{flex-direction:column}@media(orientation: landscape){#application__main__lVn1OWdchMCeTv9Bl62w{flex-direction:row}}@media(orientation: portrait){#application__main__lVn1OWdchMCeTv9Bl62w{flex-direction:column}}", "",{"version":3,"sources":["webpack://./src/main/typescript/components/globals.scss","webpack://./src/main/typescript/components/app/style/application.scss"],"names":[],"mappings":"AAUA,KACE,UAJa,CAKb,QAAA,CCVF,0EACE,WAAA,CACA,mCAAA,CAGF,yCACE,YAAA,CAGF,sFACE,qBAAA,CAGF,+BACE,yCACE,kBAAA,CAAA,CAIJ,8BACE,yCACE,qBAAA,CAAA","sourcesContent":["\n$debug: false;\n\n$body-color: null;\n@if ($debug) {\n  $body-color: red;\n} @else {\n  $body-color: black;\n}\n\nbody {\n  color: $body-color;\n  margin: 0;\n}\n","@import \"../../globals.scss\";\n\nhtml, body, body > div:first-of-type, #main {\n  width: 100vw;\n  height: calc(100vh - calc(100vh - 100%));\n}\n\n#main {\n  display: flex;\n}\n\n#main.collapsed {\n  flex-direction: column;\n}\n\n@media (orientation: landscape) {\n  #main {\n    flex-direction: row;\n  }\n}\n\n@media (orientation: portrait) {\n  #main {\n    flex-direction: column;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "application__main__lVn1OWdchMCeTv9Bl62w",
	"collapsed": "application__collapsed__H8MTHKOGMbF91cIwAwan"
};
export default ___CSS_LOADER_EXPORT___;
