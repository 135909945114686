import * as React from "react";

import { ApplicationProperties, License, LicenseType, RoleType, User as User } from "../../../types/chi";
import { CrudResource } from "../../../utils/HateoasUtils";
import styles from "./style/admin.scss";

type UserProperties = {
  csrf: ApplicationProperties.Csrf
  user: CrudResource<User>
};

type UserState = {
  user: User
}

class UserComponent extends React.Component<UserProperties, UserState> {

  constructor(props: UserProperties) {
		super(props);

    this.state = { user: props.user };
  }

  componentDidMount(): void {
    const { user } = this.props;

    fetch(user._links.license.href)
      .then(response => response.json())
      .then((license: License) => {
        user.license = {
          type: license.type,
          name: license.name
        };
        this.setState({ user });
      });
  }

  private addYearsToDate = (date: Date, years: number): Date => date.setFullYear(date.getFullYear() + years) && date;
  private toSimpleDate = (date: Date): string => `${date.getFullYear()}/${date.getMonth() + 1}/${date.getDate()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;

  private onClickActivate = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { user, csrf } = this.props;

    event.stopPropagation();
    event.preventDefault();

    let expirationDate = new Date(user.expirationDate) < this.addYearsToDate(new Date(user.createdDate), 10)
                         ?
                         this.addYearsToDate(new Date(user.createdDate), 10)
                         :
                         new Date(user.expirationDate);

    fetch("api/licenses/search/findByType?name=FULL")
    .then(response => response.json())
    .then((license: CrudResource<License>) =>
      fetch(`api/users/${user.id}/license`, {
        method: "PUT",
        headers: new Headers({
          "content-type": "application/json",
          "x-csrf-token": csrf.value
        }),
        mode: "cors",
        body: JSON.stringify({ "_links": { "license": { "href": license._links.self.href } } })
      })
      .then(response => {
        user.license = {
          type: license.type,
          name: license.name
        }
        this.setState({ user });
      })
      .catch(errorData => console.error("errorData", errorData)
    ))
    .then(_ =>
      fetch(`api/users/${user.id}`, {
        method: "PATCH",
        headers: new Headers({
          "content-type": "application/json-patch+json",
          "x-csrf-token": csrf.value
        }),
        mode: "cors",
        body: JSON.stringify([
          { "op": "replace", "path": "/expirationDate", "value": this.toSimpleDate(expirationDate) },

          // Technically a user can enable himself by clicking the link in the activation email.
          // We put this here as a failsafe, s.t. admins can still fully enable users in case
          // there is a problem with above mechanism.
          { "op": "replace", "path": "/enabled", "value": true }
        ])
      })
      .then(_ => {
        const { user } = this.state;
        user.expirationDate = expirationDate;
        user.enabled = true;
        this.setState({user});
      })
      .catch(errorData => console.error("errorData", errorData))
    );
  }

  private onClickDeactivate = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { user, csrf } = this.props;

    event.stopPropagation();
    event.preventDefault();

    fetch(`api/users/${user.id}`, {
      method: "PATCH",
      headers: new Headers({
        "content-type": "application/json-patch+json",
        "x-csrf-token": csrf.value
      }),
      mode: "cors",
      body: JSON.stringify([
        { "op": "replace", "path": "/enabled", "value": false }
      ])
    })
    .then(_ => {
      const { user } = this.state;
      user.enabled = false;
      this.setState({user})
    })
    .catch(errorData => console.error("errorData", errorData));
  }


  render() {
    const { user } = this.state;

		return (
      <tr>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.email}</td>
        <td>{new Date(user.createdDate).toDateString()}</td>
        <td>{new Date(user.expirationDate).toDateString()}</td>
        <td>
          {user.license?.name}
          &nbsp;
          <button className={styles.activate} value="Activate" onClick={this.onClickActivate} hidden={user.enabled && user.license?.type == LicenseType.FULL && new Date(user.expirationDate) > new Date()}>Activate</button>
          <button className={styles.deactivate} value="Deactivate" onClick={this.onClickDeactivate} hidden={!user.enabled || new Date(user.expirationDate) < new Date() || user.role.type == RoleType.ADMIN}>Dectivate</button>
        </td>
      </tr>
		)
	}
}

export default UserComponent;
