import * as React from "react";

import { NavigationItem, NavigationSelected } from "./Navigation";
import styles from "./style/tree.scss";

type TreeProperties = {
  navigation: {
    parts: {
      [id: string]: NavigationItem[];
    };
    selected: NavigationSelected;
  }
  items?: NavigationItem[];
  level?: number;
  prefix?: string;
  parentId?: number;
  visible: boolean;
  changeContent: (tag: string) => void;
}

type TreeState = {
  expanded: {
    [id: number]: boolean;
  }
}

class TreeComponent extends React.Component<TreeProperties, TreeState> {

  constructor(props: TreeProperties) {
		super(props);

    this.state = {
      expanded: {}
    };
  }

  private onClick = (event: React.MouseEvent<HTMLElement>) => {
    const { navigation, changeContent } = this.props;
    const { expanded } = this.state;

    event.stopPropagation();

    const element = (event.target as any).parentElement as HTMLElement;
    const value = element.getAttribute("value");
    const tags = value.split("_");
    const id = Number(tags[tags.length - 1]);

    expanded[id] = expanded[id] === undefined ? !navigation.selected.startsWith(value) : !expanded[id];

    this.setState({ expanded });

    changeContent(value);
  };

  render() {
    const { navigation, parentId, visible, changeContent } = this.props;
    let { items, prefix } = this.props;
    const { expanded } = this.state;
    const { onClick } = this;

    const level = this.props.level ?? 0;

    let prefixes: string[];
    if (!items) {
      items = Object.keys(navigation.parts).map(part => navigation.parts[part]).flat();
      prefixes = Object.keys(navigation.parts).map(part => navigation.parts[part].map(_ => part)).flat();
    }

    const list = items.map((item, idx) => {
      if (prefixes) {
        prefix = prefixes[idx];
      }

      if (expanded[item.id] == undefined && level == 0 ) {
        expanded[item.id] = true;
      }

      const key = `${prefix}_${item.id}`;

      const subTreeVisible = expanded[item.id] ||
                            (expanded[item.id] === undefined && level == 0) ||
                            (expanded[item.id] === undefined && navigation.selected.startsWith(key));

      let subTree;
      if (item.items) {
        subTree =
          <TreeComponent
            navigation={navigation}
            items={item.items}
            level={level + 1}
            prefix={key}
            parentId={item.id}
            visible={level == 0 || subTreeVisible}
            changeContent={changeContent}
          />
      }

      let span;
      if (level == 0) {
        span = <span onClick={onClick}>{item.name}</span>
      }
      else if (level == 1 && navigation.selected == key) {
        span = <span onClick={onClick} className={styles.bold}>{item.id}. {item.name}</span>
      }
      else if (level == 1) {
        span = <span onClick={onClick}>{item.id}. {item.name}</span>
      }
      else if (level == 2 && navigation.selected == key) {
        span = <span onClick={onClick} className={styles.bold}>{parentId}.{item.id}. {item.name}</span>
      }
      else if (level == 2) {
        span = <span onClick={onClick}>{parentId}.{item.id}. {item.name}</span>
      }

      return (
        <li key={key} value={key}>
          {span}
          {subTree}
        </li>);
    });

		return (
      <ul id={prefix} key={prefix} className={visible ? styles.main : styles.hidden}>
        {list}
      </ul>
		);
	}
}

export default TreeComponent;
