
import * as React from "react";

import styles from "./style/auth.scss";
import icon from "../../images/icon.png";

import { ApplicationProperties, Error, ErrorType, Language, LanguageType } from "../../types/chi";

import MessageUtils, { ApplicationMessages } from "../shared/Messages";
import LanguageUtils, { ApplicationLanguages } from "../shared/Languages";

import Login from "./Login";
import Pwlost from "./Pwlost";
import Signup from "./Signup";

type AuthProperties = ApplicationProperties;

export enum AuthComponent {
  Login,
  Pwlost,
  Signup
}

type AuthUri = "api/auth/login" | "api/auth/pwlost" | "api/auth/signup";

export type AuthView = {
  component: AuthComponent
  language: Language
  error: Error
}

export type AuthResources = {
  messages: ApplicationMessages
  languages: ApplicationLanguages
}

type AuthState = {
  view: AuthView,
  resources: AuthResources
}

type AuthComponentProperties = {
  [key in AuthComponent]: {
    uri: AuthUri,
    captcha: boolean,
    element: (propeties: {
                validateInput: (element?: HTMLInputElement) => boolean,
                resources: AuthResources,
                language: Language
              }) =>  React.ReactElement
  }
}

declare let grecaptcha: any;

const authComponentProperties: AuthComponentProperties = {
  [AuthComponent.Login]: {
    uri: "api/auth/login",
    captcha: false,
    element: ({ resources, language }) => <Login resources={resources} language={language} />
  },
  [AuthComponent.Pwlost]: {
    uri: "api/auth/pwlost",
    captcha: true,
    element: ({ validateInput, resources, language }) => <Pwlost validateInput={validateInput} resources={resources} language={language} />
  },
  [AuthComponent.Signup]: {
    uri: "api/auth/signup",
    captcha: true,
    element: ({ validateInput, resources, language }) => <Signup validateInput={validateInput} resources={resources} language={language} />
  }
};

class Auth extends React.Component<AuthProperties, AuthState> {

  private formElement: HTMLFormElement;
  private submitButton: HTMLButtonElement;

  constructor(props: AuthProperties) {
    super(props);

    this.state = {
      view: {
        component: AuthComponent.Login,
        language: props.user.language,
        error: props.application.error,
      },
      resources: {
        languages: {
          "DE": null,
          "EN": null,
          "FR": null,
        },
        messages: {
          "DE": {},
          "EN": {},
          "FR": {},
        }
      }
    };
  }

  componentDidMount() {
    const { resources } = this.state;

    Promise.all([
      MessageUtils.createApplicationMessages().then(messages => resources.messages = messages),
      LanguageUtils.createApplicationLanguages().then(languages => resources.languages = languages)
    ])
    .then(_ => this.setState({ resources }));
  }

  private setView(view: Partial<AuthView>) {
    const { component, language, error } = this.state.view;

    this.setState({
      view: {
        component: view.component ?? component,
        language: view.language ?? language,
        error: view.error ?? error
      }
    });
  }

  private validateFormData = (element?: HTMLInputElement): boolean => {
    const { resources, view } = this.state;
    const { formElement: form } = this;

    let allValid = true;

    for (const key in form.elements) {
      const input = form.elements[key] as HTMLInputElement;

      const validators: {[key: string] : RegExp} = {
        "firstName": /.+/,
        "lastName": /.+/,
        "email": /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
        "password": /.{3,}/
      }

      if (!(input.name in validators)) {
        continue;
      }

      let itemValid = input.value.match(validators[input.name]) != null;

      if (element == undefined || element.name === input.name) {
        if (itemValid) {
          input.classList.remove(styles.error);
        } else {
          input.classList.add(styles.error);
        }

        const message = resources.messages[view.language.type][`auth.${AuthComponent[view.component].toLowerCase()}.form.${input.name}.invalid`]
        input.title = itemValid ? "" : message.value;
      }
      allValid = allValid && itemValid;
    }

    this.submitButton.disabled = !allValid;

    return allValid;
  }

  private createFormData(formElement: HTMLFormElement): FormData {
    const { csrf } = this.props;
    const { view } = this.state;

    const formData = new FormData();
    formData.append(csrf.name, csrf.value);

    if (authComponentProperties[view.component].captcha) {
      formData.append("captcha", grecaptcha?.getResponse());
    }

    for (const key in formElement.elements) {

      if (formElement.elements[key] instanceof HTMLInputElement) {
        const input = formElement.elements[key] as HTMLInputElement;

        // Only consider numeric keys (input elements also appear under their name)
        if (Number.isNaN(Number(key))) {
          continue;
        }

        formData.append(input.name, input.value);
      }
    }

    return formData;
  }

  private disableFormInputs(formElement: HTMLFormElement): void {
    const { component, error } = this.state.view;

    if (component == AuthComponent.Login) {
      return;
    }

    for (const key in formElement.elements) {
      if (formElement.elements[key] instanceof HTMLInputElement) {
        const input = formElement.elements[key] as HTMLInputElement;

        input.disabled = true;
      }
    }

    this.submitButton.disabled = error.code != ErrorType.CAPTCHA_INVALID;
  }

  private submitForm = (event: React.SyntheticEvent<HTMLFormElement>) => {
    const { component, language } = this.state.view;

    event.stopPropagation();
    event.preventDefault();

    const formElement = event.currentTarget

    if (component != AuthComponent.Login && !this.validateFormData()) {
      return;
    }

    fetch(authComponentProperties[component].uri, {
      method: "POST",
      mode: "cors",
      body: this.createFormData(formElement)
    })
    .then(response => {
      if (component == AuthComponent.Login && response.status == 200) {
       window.location.href = "/" ;
      } else {
        response.json().then((error: Error) => {
          this.setState({ view: { component, error, language } });
          this.disableFormInputs(formElement);
        });
      }
    })
    .catch(errorData => console.error("errorData", errorData));
  }

  private changeComponent = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();

    const error: Error = {code: ErrorType.NONE, message: ""};

    this.submitButton.disabled = false;

    const component = AuthComponent[event.currentTarget.value as keyof typeof AuthComponent];
    switch (component) {
      case AuthComponent.Login:
        this.setView({ component: AuthComponent.Login, error });
        break;
      case AuthComponent.Pwlost:
        this.setView({ component: AuthComponent.Pwlost, error });
        break;
      case AuthComponent.Signup:
        this.setView({component: AuthComponent.Signup, error });
        break;
    }
  }

  private changeLanguage = (event: React.MouseEvent<HTMLButtonElement>) => {
    const { csrf } = this.props;
    const { languages } = this.state.resources;

    event.preventDefault();

    const language = languages[event.currentTarget.value as LanguageType];

    this.setView({ language })

    LanguageUtils.setApplicationLanguage(language, csrf);
  };

  render() {
    const { application, service } = this.props;
    const { view, resources } = this.state;
    const { component, error, language } = view;
    const { messages, languages } = this.state.resources;
    const { submitForm, changeComponent, changeLanguage, validateFormData } = this;

    if (authComponentProperties[component].captcha && service.captcha && typeof(grecaptcha) !== 'undefined') {
      grecaptcha?.reset();
    }

    const languageMessageButtons = <div className={styles.selector}>
                                     {messages[language.type]["auth.language.action"]?.value}
                                     {" "}
                                     {Object.keys(languages)
                                            .map((language, i) =>
                                              <span key={language}>
                                                <button onClick={changeLanguage} key={language} value={language} hidden={view.language.type == language}>{languages[language as LanguageType]?.name}</button>
                                                {" "}
                                              </span>
                                            )
                                     }
                                   </div>

    const componentMessageButtons = Object.keys(AuthComponent)
                                          .filter(component => isNaN(Number(component)))
                                          .map(component =>
                                            <div className={styles.selector} key={component} hidden={AuthComponent[view.component] == component}>
                                              {messages[language.type][`auth.${component.toLowerCase()}.action`]?.value.split("{0}")[0]}
                                              <button onClick={changeComponent} value={component}>{messages[language.type][`auth.${component.toLowerCase()}.button`]?.value}</button>
                                              {messages[language.type][`auth.${component.toLowerCase()}.action`]?.value.split("{0}")[1]}
                                            </div>
                                          );

    const captchaElement = <div
                             className="g-recaptcha"
                             data-sitekey="6LeZRtUgAAAAAH4pHbXngpcPcJlztiavtrPSC441"
                             style={{ display: authComponentProperties[component].captcha && service.captcha ? 'block' : 'none' }}
                           />

    const errorElement =  <div
                            id={styles.status}
                            className={error.code == ErrorType.NONE ? styles.info : styles.error}
                            hidden={!error || error.message == ""}
                          >
                            <span>{error.code == ErrorType.NONE ? "ℹ" : "⚠"}</span>
                            <span>{messages[language.type][error.message]?.value}</span>
                          </div>

    const currentComponent = authComponentProperties[component].element({ validateInput: validateFormData, resources, language })

    const preambleMessage = messages[language.type][`auth.${AuthComponent[component].toLowerCase()}.preamble`]?.value;
    const buttonMessage = messages[language.type][`auth.${AuthComponent[component].toLowerCase()}.button`]?.value;

    return (
      <div id={styles.main} >
        <table aria-hidden="true">
          <tbody>
            <tr className={styles.header}>
              <td><img src={icon}/></td>
              <td>
                <h3>
                    <span id={styles.name}>{application.name}</span>
                    <span id={styles.version}><i>{application.version}</i></span>
                </h3>
              </td>
            </tr>
            <tr className={styles.body}>
              <td colSpan={2}>
                <div>{preambleMessage}</div>
                <div>
                  <form action="login" method="post" onSubmit={submitForm} ref={(c) => this.formElement = c}>
                    {currentComponent}
                    <div id={styles.captcha}>{captchaElement}</div>
                    <div id={styles.error}>{errorElement}</div>
                    <button type="submit" ref={(c) => this.submitButton = c}>{buttonMessage}</button>
                  </form>
                </div>
                <hr/>
                {languageMessageButtons}
                {componentMessageButtons}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    )
  }
}

export default Auth;
