import * as React from "react";

import logo from "../../images/logo.png";
import { ApplicationView } from "./App";
import styles from "./style/logo.scss";

type LogoProperties = {
  view: {
    collapsed: boolean;
  }
  changeCollapsed: (collapsed: ApplicationView['collapsed']) => void;
};

class LogoComponent extends React.Component<LogoProperties> {
  private clickHandler = (event: React.MouseEvent<HTMLElement>) => {
    const { view, changeCollapsed } = this.props;

    event.preventDefault();

    changeCollapsed(!view.collapsed);
  }

  private keyPressHandler = (event: React.KeyboardEvent<HTMLElement>) => {
    const { view, changeCollapsed } = this.props;

    event.preventDefault();

    if (event.key === 'm') {
      changeCollapsed(!view.collapsed);
    }
  }

  render() {
    const { view } = this.props;
    const { clickHandler, keyPressHandler } = this;

		return (
			<div>
        <button id={styles.main} className={view.collapsed ? styles.collapsed : ""} onClick={clickHandler} onKeyPress={keyPressHandler}>
          <img src={logo} alt="logo"/>
        </button>
			</div>
		)
	}
}

export default LogoComponent;
